// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const LockedIcon = (props: PropsType): React.Node => {
  return (
    <svg
      width={props.size || '100%'}
      height={props.size || '100%'}
      viewBox="0 0 12 15"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'flex' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28053 4.47137C3.28053 3.14841 4.3715 1.94542 5.56799 1.94542C6.7648 1.94542 7.8561 3.14841 7.8561 4.47137V7.21812H3.28053V4.47137ZM10.3144 7.2181H9.82827V4.47134C9.82827 2.08808 7.83599 -0.00012207 5.56793 -0.00012207C3.3002 -0.00012207 1.30824 2.08808 1.30824 4.47134V7.2181H0.821762C0.368149 7.2181 0 7.58094 0 8.02517V14.1896C0 14.6367 0.368149 15.0002 0.821762 15.0002H10.3144C10.768 15.0002 11.1362 14.6367 11.1362 14.1896V8.02517C11.1362 7.58094 10.768 7.2181 10.3144 7.2181Z"
        fill={props.color || colors.white}
      />
    </svg>
  )
}

export default LockedIcon
