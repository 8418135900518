// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number
}

const LockedOutlinedIcon = (props: PropsType): React.Node => {
  return (
    <svg
      width={props.size || '100%'}
      height={props.size || '100%'}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='24' cy='24' r='24' fill={colors.white} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.9225 19.8C20.9225 17.9655 22.4353 16.2974 24.0945 16.2974C25.7541 16.2974 27.2673 17.9655 27.2673 19.8V23.6089H20.9225V19.8ZM30.6762 23.6089H30.0021V19.8C30.0021 16.4952 27.2394 13.5996 24.0944 13.5996C20.9498 13.5996 18.1876 16.4952 18.1876 19.8V23.6089H17.513C16.884 23.6089 16.3735 24.112 16.3735 24.728V33.276C16.3735 33.896 16.884 34.4001 17.513 34.4001H30.6762C31.3052 34.4001 31.8157 33.896 31.8157 33.276V24.728C31.8157 24.112 31.3052 23.6089 30.6762 23.6089V23.6089Z'
        fill={colors.orange}
      />
    </svg>
  )
}

export default LockedOutlinedIcon
